import React from // { useEffect, useState }
"react";
import Layout from "../layout/layout";
import "./style.css";
//import Payment from "./payment";
import BannerSlider from "./bannerSlider";
// import Casino from "./casino";
// import LiveCasino from "./liveCasino";
//import BuyCasino from "./buycasino";
//import Promotion from "./promotion";
// import { getLandingGames } from "../../utility/Apis";
// import { toast } from "react-toastify";
// import { useMyContext } from "../context";
// import { useNavigate } from "react-router-dom";

function Home() {
  // const navigate = useNavigate();
  // const { loading, setLoading, userDetails, setIsGamePlaying } = useMyContext();
  // const [landingGames, setLandingGames] = useState([]);
  // const { gameShow, setGameShow } = useMyContext();
  // const handleGameShow = () => {
  //   setGameShow(true);
  // };

  // const handleGameClose = () => {
  //   navigate("/view-all/false/lobby/lobby");;
  //   setGameShow(false);
  //   setIsGamePlaying(false);
  // };

  // const headerGamePopup = gameShow && "gamePopup";

  // landing games api is calling here
  // const fetchLandingGames = async () => {
  //   try {
  //     setLoading(true);
  //     const res = await getLandingGames();
  //     if (res?.msg === "success") {
  //       setLandingGames(res?.data?.[0]);
  //     } else {
  //       toast.error(res?.msg, { toastId: "game-error" });
  //     }
  //   } catch (error) {
  //     toast.error(error?.message);
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchLandingGames();
  //   // eslint-disable-next-line
  // }, []);
  return (
    <Layout
    // headerGamePopup={headerGamePopup}
    >
      <div className="container">
        <div className="home-page-content">
          <BannerSlider />
          {/* <Casino
            handleGameShow={handleGameShow}
            landingGames={landingGames}
            loading={loading}
            handleGameClose={handleGameClose}
            gameShow={gameShow}
            userDetails={userDetails}
          /> */}
          {/* <LiveCasino /> */}
          {/* <BuyCasino /> */}
          {/* <Payment /> */}
          {/* <Promotion /> */}
        </div>
      </div>
    </Layout>
  );
}

export default Home;
