import React, { useEffect, useState } from "react";
import AccountLayout from "./index";
import { useMyContext } from "../context";
import edit from "../../assets/images/icons/edit.svg";
// import btc from "../../assets/images/header/balance-icon/BTC.svg";
import gcoin from "../../assets/images/header/new-gold-coin.svg";
// import eth from "../../assets/images/header/balance-icon/ETH.svg";
// // import xrp from "../../assets/images/header/balance-icon/XRP.svg";
// import ltc from "../../assets/images/header/balance-icon/LTC.svg";
import dashboard from "../../assets/images/header/user-profile-icon/dashboard.svg";
import close from "../../assets/images/header/icon/close.svg";
import { Link, useNavigate } from "react-router-dom";
import { last5Transaction } from "../../utility/Apis";

function Dashboard() {
  const { windowWidth, userDetails } = useMyContext();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handleButtonClick = () => {
    // window.location.href = "/";
    navigate("/view-all/false/lobby/lobby");;
  };
  //let [loading, setLoading] = useState(false);
  const [transaction, setTransaction] = useState([]);
  const getAllTransactions = async () => {
    try {
      // setLoading(true);
      const res = await last5Transaction();
      if (res?.status === 200) {
        setTransaction(res?.history);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    getAllTransactions();
  }, []);
  return (
    <AccountLayout>
      {windowWidth <= 991 ? (
        <div className="dashboard-content">
          <div className="dashboard-title">
            <div className="dashboard-container">
              <img src={dashboard} alt="dashboard" />
              <h4 text_key="DASHBOARD__DASHBOARD">DASHBOARD</h4>
            </div>

            <img
              src={close}
              className="close"
              alt="Close"
              onClick={handleButtonClick}
            />
          </div>
          <section className="profile">
            <div className="content center">
              <Link to="/account/personalinfo" className="edit">
                <img src={edit} alt="edit" />
              </Link>
              <h3 className="greeting">WELCOME {userDetails?.firstName}</h3>
              <p className="id">ID-{userDetails?.id}</p>
              <p className="email">{userDetails?.email}</p>
              <a href="/account/kyc">
                <button className="verify" text_key="DASHBOARD__VERIFY">
                  VERIFY
                </button>
              </a>
            </div>
          </section>
          <section className="wallets">
            <div className="content">
              <h2 className="section-title" text_key="DASHBOARD__WALLETS">
                Wallets
              </h2>
              <div className={`currencies ${!show ? "" : "active"}`}>
                <div className="currency" cid="332">
                  <span className="value">
                    {userDetails?.cryptoWallet?.USD
                      ? Number(userDetails?.cryptoWallet?.USD)?.toFixed(2) ||
                        "0.00"
                      : "0.00"}
                  </span>
                  <div className="currency-container">
                    <img src={gcoin} alt="GC" />
                    {/* <span className="name">GC</span> */}
                  </div>
                </div>
                {/* 
                <div className="currency" cid="334">
                  <span className="value">
                    {userDetails?.cryptoWallet?.ETH?.toFixed(7)}
                  </span>
                  <div className="currency-container">
                    <span className="name">ETH</span>
                    <img src={eth} alt="ETH" />
                  </div>
                </div>
                <div className="currency" cid="336">
                  <span className="value">
                    {userDetails?.cryptoWallet?.LTC?.toFixed(7)}
                  </span>
                  <div className="currency-container">
                    <span className="name">LTC</span>
                    <img src={ltc} alt="LTC" />
                  </div>
                </div> */}
              </div>
              <button
                className="view-more"
                text_key="DASHBOARD__VIEW_ALL"
                onClick={handleShow}
              >
                {show ? " View more" : "View less"}
              </button>
            </div>
          </section>
          <section className="transactions">
            <h2 className="title" text_key="DASHBOARD__LATEST_TRANSACTIONS">
              Latest Transactions
            </h2>
            <div className="content">
              <div className="transactions"></div>
              <button className="view-more">
                <Link to="/account/transactions" text_key="DASHBOARD__VIEW_ALL">
                  View all
                </Link>
              </button>
            </div>
          </section>
        </div>
      ) : (
        <div className="dashboard-page-wrapper">
          <div className="personal-info">
            <ul>
              <li className="balance">
                <div className="left">
                  <span text_key="PROFILE_DASHBOARD__BALANCE~:">Balance :</span>
                  <p className="amount">
                    {userDetails?.cryptoWallet?.USD ? (
                      <span>
                        {userDetails?.cryptoWallet
                          ? Number(userDetails?.cryptoWallet?.USD)?.toFixed(2)
                          : "0.00"}
                      </span>
                    ) : (
                      <span>0.00</span>
                    )}

                    {/* {" "}<span>GC</span> */}
                  </p>
                </div>
                {/* <span
                  className="deposit btn not-webshop"
                  onClick={() => {
                    "deposit");
                  }}
                  text_key="PROFILE_DASHBOARD__DEPOSIT"
                >
                  Deposit
                </span> */}
              </li>
              <li className="my-profile">
                <Link className="content" to="/account/personalinfo">
                  <span className="icon material-icons">assignment_ind</span>
                  <span text_key="PROFILE_DASHBOARD__MY_PROFILE">
                    My Profile
                  </span>
                </Link>
              </li>
              {/* <li className="not-verified">
                <div className="content">
                  <div className="icon material-icons">
                    {userDetails?.isEmailVerified ? "verified" : "info"}
                  </div>
                  <div className="status">
                    <span
                      className="verification"
                      text_key="PROFILE_DASHBOARD__VERIFICATION"
                    >
                      Verification
                    </span>
                    <span
                      className="isverified"
                      text_key="PROFILE_DASHBOARD__NOT_VERIFIED"
                    >
                      {userDetails?.isEmailVerified
                        ? "Verified"
                        : "Not Verified"}
                    </span>
                  </div>
                </div>
              </li> */}
              {/* <li className="verified">
                <div className="content">
                  <div className="status">
                    <span
                      className="verification"
                      text_key="PROFILE_DASHBOARD__VERIFICATION"
                    >
                      Verification
                    </span>
                    <span
                      className="isverified"
                      text_key="PROFILE_DASHBOARD__VERIFIED"
                    >
                      "Not Verified"
                    </span>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
          {/* <section className="bonuses">
            <h3 text_key="PROFILE_DASHBOARD__BONUSES">Bonuses</h3>
            <div className="bonus-list"> */}
          {/* <span className="material-icons arrow left">keyboard_arrow_left</span> */}
          {/* <div className="list-wrapper">
                <ul></ul>
              </div> */}
          {/* <span className="material-icons arrow right">keyboard_arrow_right</span> */}
          {/* </div>
          </section> */}
          {/* <section className="wallets">
            <h3 text_key="PROFILE_DASHBOARD__ALL_WALLETS">All Wallets</h3>
            <ul className="added"></ul>
          </section> */}
          <section className="transactions">
            <h3>
              <span text_key="PROFILE_DASHBOARD__LAST_TRANSACTIONS">
                Last Transactions
              </span>
              <a
                href="/account/transactions"
                text_key="PROFILE_DASHBOARD__SEE_ALL"
              >
                See All
              </a>
            </h3>
            <div className="dashboard-table overflow-auto">
            <table className="bets-table   table-responsive  ">
              <thead>
                <tr>
                  <th text_key="PROFILE_TRANSACTION__DATE/TIME">Date/Time</th>
                  <th text_key="PROFILE_DASHBOARD__TRANSACTION_TYPE">
                    Transaction Type
                  </th>
                  {/* <th text_key="PROFILE_TRANSACTION__CURRENCY_CODE">
                    Currency Code
                  </th> */}
                  <th text_key="PROFILE_DASHBOARD__AMOUNT">Amount</th>
                  {/* <th text_key="PROFILE_DASHBOARD__DATE">Data</th> */}
                  <th text_key="PROFILE_TRANSACTION__RESULT">Status</th>
                </tr>
              </thead>
              {transaction &&
                transaction?.map((items) => (
                  <tbody>
                    <tr>
                      <th text_key="PROFILE_TRANSACTION__DATE/TIME">
                        {items?.updatedAt &&
                          `${new Date(items?.updatedAt).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}`}
                        &nbsp;
                        {`${new Date(items?.updatedAt).toLocaleTimeString(
                          "en-US",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )}`}
                      </th>
                      <th text_key="PROFILE_DASHBOARD__TRANSACTION_TYPE">
                        {items?.transactionType || ""}
                      </th>
                      {/* <th text_key="PROFILE_TRANSACTION__TYPE">
                            {items?.userId}
                          </th> */}
                      {/* <th text_key="PROFILE_TRANSACTION__CURRENCY_CODE">
                        {items?.currencyCode || ""}
                      </th> */}
                      <th text_key="PROFILE_DASHBOARD__AMOUNT">
                        {items?.usdAmount || "0.00"}
                      </th>
                      <th text_key="PROFILE_TRANSACTION__RESULT">
                        {items?.status || ""}
                      </th>
                      {/* <th></th> */}
                    </tr>
                  </tbody>
                ))}
            </table>
            </div>
          </section>
        </div>
      )}
    </AccountLayout>
  );
}

export default Dashboard;
