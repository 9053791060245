import React from "react";
import CategoryLayout from "./index";

function RefundPolicy() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">Refund Policy</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>REFUND POLICY</strong>
            </p>
            <p className="MsoNormal">Last updated: 02.05.2022&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>
                Carefully read the following policy describing the refund
                practices for versebit.
              </strong>
            </p>
            <p className="MsoNormal">
              Make sure you understand our&nbsp;Terms and Conditions.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ol start="1" type="1">
              <li className="MsoNormal">
                Refund is not available if the alleged deposit and bonuses have
                been played using the Services.
              </li>
              <li className="MsoNormal">
                A user should request the refund action only within the first
                twenty-four (24) hours of the alleged transaction. In case a
                Player alleges that another individual has accessed his/her
                Player Account, refund action can be requested within thirty
                (30) days.
              </li>
              <li className="MsoNormal">
                All the users should be playing in a fair manner, meaning, they
                should not impact the result of a game using computer aids or
                other methods.
              </li>
            </ol>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default RefundPolicy;
