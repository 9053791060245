import React from "react";
import banner from "../../assets/images/promo/banner.webp";
import { useMyContext } from "../context";

function PromoDetailPopup({ handlePromoClose }) {
  const { handleShowTransection, forLogin, handleSignupShow, windowWidth } =
    useMyContext();

  return (
    <div className="promo-popup box">
      <div className="header">
        <div className="title dotted-hidden"> Welcome Casino Bonus</div>
        {windowWidth >= 991 && (
          <>
            {forLogin ? (
              <div
                className="btn"
                onClick={() => {
                  handleShowTransection("deposit");
                }}
              >
                Deposit
              </div>
            ) : (
              <div className="btn" onClick={handleSignupShow}>
                Register
              </div>
            )}
          </>
        )}

        <span className="material-icons close" onClick={handlePromoClose}>
          close
        </span>
      </div>
      <div className="body">
        <div className="content">
          <div className="banner-wrapper">
            <img src={banner} alt="banner" />
          </div>
          <div className="text">
            <div>
              <p style={{ textAlign: "center" }}>
                <strong>50 FreeSpins - WAGER FREE</strong>
              </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                <strong>
                  MAKE YOU FIRST DEPOSIT 50$ OR MORE→ACTIVATE PROMO CODE
                  (VERSEVIT50) →RECEIVE 50 FREESPINS WITHOUT WAGER
                </strong>
              </p>
              <p>
                <br />
                <br />
              </p>
              <ul>
                <li>
                  This offer is only available to new customers, who have
                  registered and made their first real-money deposit on
                  versebit.
                  <br />
                  <br />
                </li>
                <li>
                  The Minimum deposit amount eligible for the Bonus is:{" "}
                  <strong>
                    50 USD
                    <br />
                    <br />
                  </strong>
                </li>
                <li>
                  After the deposit, you have <strong>24 HOURS</strong> to
                  activate the bonus and <strong>24 HOURS</strong> to use it.
                  <br />
                  <br />
                </li>
                <li>
                  To activate the bonus you should follow the following steps:{" "}
                  <strong>
                    My Account –&gt; FreeSpin Promo -&gt; Enter the FreeSpin
                    code
                    <br />
                    <br />
                  </strong>
                </li>
                <li>
                  FreeSpin Code:{" "}
                  <strong>
                    VERSEVIT50
                    <br />
                    <br />
                  </strong>
                </li>
                <li>
                  Game:{" "}
                  <strong>
                    Lazy Sheriff
                    <br />
                    <br />
                  </strong>
                </li>
                <li>
                  The maximum winnings that will be given as a result of Free
                  spins is{" "}
                  <strong>
                    100 USD
                    <br />
                    <br />
                  </strong>
                </li>
                <li>
                  You can only benefit from this bonus: ONCE and ONLY on your
                  First Deposit.
                  <br />
                  <br />
                </li>
                <li>
                  Free spins are WAGER FREE! Winnings from the free spins are
                  directly credited to the real balance.
                  <br />
                  <br />
                </li>
                <li>
                  Free sign-up spins requested through an affiliate site may be
                  subject to different terms and conditions (e.g. wagering).
                  Bonus terms and conditions must be verified on the affiliate
                  site unless otherwise stated.
                  <br />
                  <br />
                </li>
                <li>
                  At the same time, as part of our general terms and conditions,
                  it is necessary to bet at least 1 time the amount of its first
                  deposit and the amount won in free spins to be able to make
                  its withdrawal. For example Deposit: $50, you receive free
                  spins winnings: $20, you must wager $70 to be able to
                  withdraw.
                  <br />
                  <br />
                </li>
                <li>
                  Stakes under 1.3 odds will not be counted toward the wagering
                  requirement.
                  <br />
                  <br />
                </li>
              </ul>
              <p>
                Jackbit reserves the right to modify, cancel, or terminate the
                promotion at any time.
                <br />
                <br />
              </p>
              <p>
                <strong>General Terms and Conditions apply.</strong>
              </p>
            </div>
            <div className="text-btn">
              {windowWidth <= 991 && (
                <>
                  {forLogin ? (
                    <div
                      className="btn"
                      onClick={() => {
                        handleShowTransection("deposit");
                      }}
                    >
                      Deposit
                    </div>
                  ) : (
                    <div className="btn" onClick={handleSignupShow}>
                      Register
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoDetailPopup;
