import React, { useEffect, useState } from "react";
import Layout from "../layout/layout";
import "./style.css";
import dashboard from "../../assets/images/header/user-profile-icon/dashboard.svg";
//import deposit from "../../assets/images/header/user-profile-icon/depositMoney.svg";
//import withdraw from "../../assets/images/header/user-profile-icon/withdrawMoney.svg";
// import buy from "../../assets/images/header/user-profile-icon/buyCrypto.svg";
//import gifts from "../../assets/images/header/user-profile-icon/gifts.svg";
//import freespins from "../../assets/images/header/user-profile-icon/bonus.svg";
// import notifications from "../../assets/images/header/user-profile-icon/notifications.svg";
import personalInfo from "../../assets/images/header/user-profile-icon/personalInfo.svg";
import transactions from "../../assets/images/header/user-profile-icon/transactions.svg";
import mybets from "../../assets/images/header/user-profile-icon/tik.svg";
// import verification from "../../assets/images/header/user-profile-icon/verification.svg";
import { useMyContext } from "../context";
import { Link } from "react-router-dom";

function AccountLayout({ children }) {
  const { handleShowTransection } = useMyContext();

  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeLink") || "/account/dashboard"
  );

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    localStorage.setItem("activeLink", activeLink);
  }, [activeLink]);

  useEffect(() => {
    setActiveLink(window.location.pathname);
  }, []);


  return (
    <Layout>
      <div className="container">
        <div className="account-content">
          <Sidebar
            handleShowTransection={handleShowTransection}
            activeLink={activeLink}
            handleLinkClick={handleLinkClick}
          />
          <div className="page-wrapper">{children}</div>
        </div>
      </div>
    </Layout>
  );
}

export default AccountLayout;

function Sidebar({ handleShowTransection, handleLinkClick, activeLink }) {
  return (
    <nav className="account-sidebar middle box">
      <div className="g-title" text_key="PROFILE_MENU__ACCAOUNTMENU">
        {" "}
        Account Menu
      </div>
      <ul>
        <li>
          <Link
            to="/account/dashboard"
            className={activeLink === "/account/dashboard" ? "active" : ""}
            onClick={() => handleLinkClick("/account/dashboard")}
          >
            <div className="icon">
              <img src={dashboard} alt="dashboard" />
            </div>
            <span text_key="PROFILE_MENU__DASHBOARD">Dashboard</span>
          </Link>
        </li>
        {/* <li className="not-webshop">
          <div
            className="sidebar-click-content"
            onClick={() => {
              handleShowTransection("deposit");
            }}
          >
            <div className="icon">
              <img src={deposit} alt="depositMoney" />
            </div>
            <span text_key="PROFILE_MENU__DEPOSIT_MONEY">Deposit Money</span>
          </div>
        </li> */}
        {/* <li className="not-webshop">
          <div
            className="sidebar-click-content"
            onClick={() => {
              handleShowTransection("withdraw");
            }}
          >
            <div className="icon">
              <img src={withdraw} alt="withdrawMoney" />
            </div>
            <span text_key="PROFILE_MENU__WITHDRAW_MONEY">Withdraw Money</span>
          </div>
        </li> */}
        {/* <li className="not-webshop">
          <div
            className="sidebar-click-content"
            onClick={() => {
              handleShowTransection("byCrypto");
            }}
          >
            <div className="icon">
              <img src={buy} alt="buyCrypto" />
            </div>
            <span text_key="PROFILE_MENU__BUY_CRYPTO">Buy Crypto</span>
          </div>
        </li> */}
        {/* <li>
          <Link
            to="/account/gift"
            className={activeLink === "/account/gift" ? "active" : ""}
            onClick={() => handleLinkClick("/account/gift")}
          >
            <div className="icon">
              <img src={gifts} alt="bonus" />
            </div>
            <span text_key="PROFILE_MENU__GIFTS">Gifts</span>
          </Link>
        </li> */}
        {/* <li>
          <Link
            to="/account/freespinsbypromo"
            className={
              activeLink === "/account/freespinsbypromo" ? "active" : ""
            }
            onClick={() => handleLinkClick("/account/freespinsbypromo")}
          >
            <div className="icon">
              <img src={freespins} alt="freespinsbypromo" />
            </div>
            <span text_key="PROFILE_MENU__FREESPINS_BY_PROMO">
              Freespin Promo
            </span>
          </Link>
        </li> */}
        {/* <li>
          <Link
            to="/account/notifications"
            className={activeLink === "/account/notifications" ? "active" : ""}
            onClick={() => handleLinkClick("/account/notifications")}
          >
            <div className="icon">
              <img src={notifications} alt="notifications" />
            </div>
            <span text_key="PROFILE_MENU__NOTIFICATIONS">Notifications</span>
          </Link>
        </li> */}
        <li>
          <Link
            to="/account/personalinfo"
            className={activeLink === "/account/personalinfo" ? "active" : ""}
            onClick={() => handleLinkClick("/account/personalinfo")}
          >
            <div className="icon">
              <img src={personalInfo} alt="personalInfo" />
            </div>
            <span text_key="PROFILE_MENU__PERSONAL_INFORMATION">
              Personal Information
            </span>
          </Link>
        </li>
        <li>
          <Link
            to="/account/transactions"
            className={activeLink === "/account/transactions" ? "active" : ""}
            onClick={() => handleLinkClick("/account/transactions")}
          >
            <div className="icon">
              <img src={transactions} alt="transactions" />
            </div>
            <span text_key="PROFILE_MENU__TRANSACTIONS_HISTORY">
              Transactions History
            </span>
          </Link>
        </li>
        <li className="sportsbook">
          <Link
            to="/account/bethistory"
            className={activeLink === "/account/bethistory" ? "active" : ""}
            onClick={() => handleLinkClick("/account/bethistory")}
          >
            <div className="icon">
              <img src={mybets} alt="transactions" />
            </div>
            <span text_key="PROFILE_MENU__BET_HISTORY">My Bets</span>
          </Link>
        </li>
        {/* <li>
          <Link
            to="/account/kyc"
            className={activeLink === "/account/kyc" ? "active" : ""}
            onClick={() => handleLinkClick("/account/kyc")}
          >
            <div className="icon">
              <img src={verification} alt="verification" />
            </div>
            <span text_key="PROFILE_MENU__ACCOUNT_VERIFICATION">
              Account Verification
            </span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}
