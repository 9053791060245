import React from "react";
// import sport from "../../assets/images/home/category/sports.png";
import casino from "../../assets/images/home/category/casino.png";
import livecasino from "../../assets/images/home/category/live-casino.png";
import { Link } from "react-router-dom";
// import minigame from "../../assets/images/home/category/mini-game.jpg";

function Category() {
  const categoryData = [
    {
      className: "",
      linkClassName: "casino",
      imageSrc: casino,
      alt: "casino",
      nameTextKey: "MAIN_PAGE__CASINO",
      name: "Casino",
      link: "/view-all/false/lobby/lobby",
    },
    {
      className: "",
      linkClassName: "live-casino",
      imageSrc: livecasino,
      alt: "livecasino",
      nameTextKey: "MAIN_PAGE__LIVE_CASINO",
      name: "Live Casino",
      link: "/view-all/false/lobby/lobby",
    },
  ];

  return (
    <section className="categories">
      <div className="categories-wrapper">
        {categoryData.map((category, index) => (
          <div className={`category ${category.className}`} key={index}>
            <Link to={category.link} className={category.linkClassName}>
              <img src={category.imageSrc} alt={category.alt} />
              <span className="name" text_key={category.nameTextKey}>
                {category.name}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Category;
