import React, { useState } from "react";
import Layout from "../layout/layout";
import promotion1 from "../../assets/images/home/promotion/promotion1.webp";
import promotion2 from "../../assets/images/home/promotion/promotion2.webp";
import promotion3 from "../../assets/images/home/promotion/promotion3.webp";
import promotion4 from "../../assets/images/home/promotion/promotion4.webp";
import promotion5 from "../../assets/images/home/promotion/promotion5.webp";
import promotion6 from "../../assets/images/home/promotion/promotion6.webp";
import promotion7 from "../../assets/images/home/promotion/promotion7.webp";
import promotion8 from "../../assets/images/home/promotion/promotion8.webp";
import promotion9 from "../../assets/images/home/promotion/promotion9.webp";
import "./style.css";
import { Modal } from "react-bootstrap";
import PromoDetailPopup from "../modals/promoPopup";
import { useMyContext } from "../context";

function Promotion() {
  const { handleShowTransection, handleSignupShow, forLogin } = useMyContext();
  const [selectedTab, setSelectedTab] = useState("All");
  const [isPromo, setIsPromo] = useState(false);

  const promotions = [
    {
      imageSrc: promotion1,
      title: "Welcome Casino Bonus",
      description: "50 FreeSpins - WAGER FREE",
    },
    {
      imageSrc: promotion2,
      title: "Bet Insurance",
      description: "Bet Insurance - 10% Cashback as a Freebet",
    },
    {
      imageSrc: promotion3,
      title: "3+1 FreeBet",
      description: "Get Every 4th Ticket as a GIFT in Sports",
    },
    {
      imageSrc: promotion4,
      title: "Drops & Wins",
      description:
        "Drops & Wins – ‘Cash Prizes All Day Long – It only takes a spin to win’ ",
    },
    {
      imageSrc: promotion5,
      title: "Bet Builder",
      description: "Bet Builder - Build Your Own Bets",
    },
    {
      imageSrc: promotion6,
      title: "CashOut",
      description: "CASHOUT TILL IT’S TOO LATE",
    },
    {
      imageSrc: promotion7,
      title: "Grand Holidays Tournament",
      description: "Total Prize Pool: 500,000 USD/EUR",
    },
    {
      imageSrc: promotion8,
      title: "Save/Delete/Replace",
      description:
        "Get to know versebit sporstbetting features - Save/Delete/Replace",
    },
    {
      imageSrc: promotion9,
      title: "FreeSpins & FreeBets",
      description: "RULES FOR ALL FREE SPINS ON OUR CASINO",
    },
  ];

  const sportPromotions = [
    {
      imageSrc: promotion2,
      title: "Bet Insurance",
      description: "Bet Insurance - 10% Cashback as a Freebet",
    },
    {
      imageSrc: promotion3,
      title: "3+1 FreeBet",
      description: "Get Every 4th Ticket as a GIFT in Sports",
    },

    {
      imageSrc: promotion5,
      title: "Bet Builder",
      description: "Bet Builder - Build Your Own Bets",
    },

    {
      imageSrc: promotion7,
      title: "Grand Holidays Tournament",
      description: "Total Prize Pool: 500,000 USD/EUR",
    },
    {
      imageSrc: promotion8,
      title: "Save/Delete/Replace",
      description:
        "Get to know versebit sporstbetting features - Save/Delete/Replace",
    },
  ];

  const casinoPromotions = [
    {
      imageSrc: promotion1,
      title: "Welcome Casino Bonus",
      description: "50 FreeSpins - WAGER FREE",
    },

    {
      imageSrc: promotion4,
      title: "Drops & Wins",
      description:
        "Drops & Wins – ‘Cash Prizes All Day Long – It only takes a spin to win’ ",
    },

    {
      imageSrc: promotion7,
      title: "Grand Holidays Tournament",
      description: "Total Prize Pool: 500,000 USD/EUR",
    },
  ];

  const otherPromotions = [
    {
      imageSrc: promotion9,
      title: "FreeSpins & FreeBets",
      description: "RULES FOR ALL FREE SPINS ON OUR CASINO",
    },
  ];

  const handlePromoShow = (e) => {
    setIsPromo(true);
    e.preventDefault();
    e.stopPropagation();

    // history.push("/static/promos");
  };

  const handlePromoClose = () => {
    setIsPromo(false);
  };

  return (
    <Layout>
      <div className="container">
        <div className="promotion-content">
          <div className="filters">
            <ul>
              <li
                onClick={() => setSelectedTab("All")}
                className={selectedTab === "All" ? "active" : ""}
              >
                All
              </li>
              <li
                onClick={() => setSelectedTab("Sport")}
                className={selectedTab === "Sport" ? "active" : ""}
              >
                Sport
              </li>
              <li
                onClick={() => setSelectedTab("Casino")}
                className={selectedTab === "Casino" ? "active" : ""}
              >
                Casino
              </li>
              <li
                onClick={() => setSelectedTab("Other")}
                className={selectedTab === "Other" ? "active" : ""}
              >
                Other
              </li>
            </ul>
          </div>
          {selectedTab === "All" && (
            <PromoCard
              promotions={promotions}
              handlePromoShow={handlePromoShow}
              forLogin={forLogin}
              handleSignupShow={handleSignupShow}
              handleShowTransection={handleShowTransection}
            />
          )}
          {selectedTab === "Sport" && (
            <SportPromoCard
              sportPromotions={sportPromotions}
              handlePromoShow={handlePromoShow}
              forLogin={forLogin}
              handleSignupShow={handleSignupShow}
              handleShowTransection={handleShowTransection}
            />
          )}
          {selectedTab === "Casino" && (
            <CasinoPromoCard
              casinoPromotions={casinoPromotions}
              handlePromoShow={handlePromoShow}
              forLogin={forLogin}
              handleSignupShow={handleSignupShow}
              handleShowTransection={handleShowTransection}
            />
          )}
          {selectedTab === "Other" && (
            <OtherPromoCard
              otherPromotions={otherPromotions}
              handlePromoShow={handlePromoShow}
              forLogin={forLogin}
              handleSignupShow={handleSignupShow}
              handleShowTransection={handleShowTransection}
            />
          )}
        </div>
      </div>
      <Modal
        show={isPromo}
        onHide={handlePromoClose}
        centered
        className="jackbit-modal"
      >
        <Modal.Body>
          <PromoDetailPopup handlePromoClose={handlePromoClose} />
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default Promotion;

function PromoCard({
  promotions,
  handlePromoShow,
  forLogin,
  handleSignupShow,
  handleShowTransection,
}) {
  return (
    <div>
      <ul className="promos">
        {promotions.map((promo) => (
          <li key={promo.id}>
            <div
              className="promo-img"
              style={{ backgroundImage: `url(${promo.imageSrc})` }}
            ></div>
            <div className="info">
              <h2 className="dotted-hidden" title={promo.title}>
                {promo.title}
              </h2>
              <div className="text-content">
                <p>
                  <strong>{promo.description}</strong>
                </p>
              </div>

              <div className="btn-wrapper">
                <div
                  className="read-more btn"
                  text_key="PROMO__READ_MORE"
                  onClick={handlePromoShow}
                >
                  Read More
                </div>
                {forLogin ? (
                  <span
                    className="btn"
                    text_key="PROMO__DEPOSIT"
                    onClick={() => {
                      handleShowTransection("deposit");
                    }}
                  >
                    Deposit
                  </span>
                ) : (
                  <span
                    className="btn"
                    text_key="PROMO__REGISTER"
                    onClick={handleSignupShow}
                  >
                    Register
                  </span>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
function SportPromoCard({
  sportPromotions,
  handlePromoShow,
  forLogin,
  handleSignupShow,
  handleShowTransection,
}) {
  return (
    <div>
      <ul className="promos">
        {sportPromotions.map((promo) => (
          <li key={promo.id}>
            <div
              className="promo-img"
              style={{ backgroundImage: `url(${promo.imageSrc})` }}
            ></div>
            <div className="info">
              <h2 className="dotted-hidden" title={promo.title}>
                {promo.title}
              </h2>
              <div className="text-content">
                <p>
                  <strong>{promo.description}</strong>
                </p>
              </div>

              <div className="btn-wrapper">
                <div
                  className="read-more btn"
                  text_key="PROMO__READ_MORE"
                  onClick={handlePromoShow}
                >
                  Read More
                </div>
                {forLogin ? (
                  <span
                    className="btn"
                    text_key="PROMO__DEPOSIT"
                    onClick={() => {
                      handleShowTransection("deposit");
                    }}
                  >
                    Deposit
                  </span>
                ) : (
                  <span
                    className="btn"
                    text_key="PROMO__REGISTER"
                    onClick={handleSignupShow}
                  >
                    Register
                  </span>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
function CasinoPromoCard({
  casinoPromotions,
  handlePromoShow,
  forLogin,
  handleSignupShow,
  handleShowTransection,
}) {
  return (
    <div>
      <ul className="promos">
        {casinoPromotions.map((promo) => (
          <li key={promo.id}>
            <div
              className="promo-img"
              style={{ backgroundImage: `url(${promo.imageSrc})` }}
            ></div>
            <div className="info">
              <h2 className="dotted-hidden" title={promo.title}>
                {promo.title}
              </h2>
              <div className="text-content">
                <p>
                  <strong>{promo.description}</strong>
                </p>
              </div>

              <div className="btn-wrapper">
                <div
                  className="read-more btn"
                  text_key="PROMO__READ_MORE"
                  onClick={handlePromoShow}
                >
                  Read More
                </div>
                {forLogin ? (
                  <span
                    className="btn"
                    text_key="PROMO__DEPOSIT"
                    onClick={() => {
                      handleShowTransection("deposit");
                    }}
                  >
                    Deposit
                  </span>
                ) : (
                  <span
                    className="btn"
                    text_key="PROMO__REGISTER"
                    onClick={handleSignupShow}
                  >
                    Register
                  </span>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
function OtherPromoCard({
  otherPromotions,
  handlePromoShow,
  forLogin,
  handleSignupShow,
  handleShowTransection,
}) {
  return (
    <div>
      <ul className="promos">
        {otherPromotions.map((promo) => (
          <li key={promo.id}>
            <div
              className="promo-img"
              style={{ backgroundImage: `url(${promo.imageSrc})` }}
            ></div>
            <div className="info">
              <h2 className="dotted-hidden" title={promo.title}>
                {promo.title}
              </h2>
              <div className="text-content">
                <p>
                  <strong>{promo.description}</strong>
                </p>
              </div>

              <div className="btn-wrapper">
                <div
                  className="read-more btn"
                  text_key="PROMO__READ_MORE"
                  onClick={handlePromoShow}
                >
                  Read More
                </div>
                {forLogin ? (
                  <span
                    className="btn"
                    text_key="PROMO__DEPOSIT"
                    onClick={() => {
                      handleShowTransection("deposit");
                    }}
                  >
                    Deposit
                  </span>
                ) : (
                  <span
                    className="btn"
                    text_key="PROMO__REGISTER"
                    onClick={handleSignupShow}
                  >
                    Register
                  </span>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
