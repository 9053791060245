import React from "react";
import CategoryLayout from "./index";

function SelfExclusion() {
  return (
    <CategoryLayout>
      <>
        <div className="head-content">
          <h1 className="title">Self-Exclusion</h1>
          <span></span>
        </div>
        <div className="content">
          <div className="text-content">
            <p className="MsoNormal">
              <strong>Self-Exclusion</strong>
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              To assist our players in playing responsibly, we provide a
              selection of options:
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <ul type="disc">
              <li className="MsoNormal">Account limits</li>
              <li className="MsoNormal">Time out periods</li>
              <li className="MsoNormal">Self-exclusion periods</li>
              <li className="MsoNormal">24/7 Customer Support</li>
              <li className="MsoNormal">
                Account tools to help you manage your gambling
              </li>
              <li className="MsoNormal">
                Direct links to responsible gambling organizations
              </li>
            </ul>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Gambling Management Tools</strong>
            </p>
            <p className="MsoNormal">
              Versebit offers you the solution in case you need to control your
              behavior connected to our website:
            </p>
            <p className="MsoNormal">
              Gambling Management Tools is the best way to set a limitation by
              yourself. It helps you control your gambling through simple steps.
            </p>
            <p className="MsoNormal">
              In order to set a daily, weekly or monthly deposit limit for your
              playing activity, please contact our Customer Support team who
              will be happy to set your limits based on your request.
            </p>
            <p className="MsoNormal">
              The daily, weekly and monthly limits begin on the date and time
              requested by you, and will be reset once your requested period has
              expired.&nbsp;
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Time Out Period</strong>
            </p>
            <p className="MsoNormal">
              If you need a break from gambling, please contact our Customer
              Support team and ask them to temporarily close your account.
            </p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">&nbsp;</p>
            <p className="MsoNormal">
              <strong>Self-Exclusion</strong>
            </p>
            <p className="MsoNormal">
              If the situation is more serious, you may want to exclude yourself
              from gambling for an extended period of time. You can contact our
              Customer Support team at any time requesting self-exclusion for a
              period of between 6 months and 5 years.
            </p>
          </div>
        </div>
      </>
    </CategoryLayout>
  );
}

export default SelfExclusion;
