import { useEffect } from "react";
import { getSessionId } from "./Apis";

export default function SessionWrapper({ children }) {
  useEffect(() => {
    if (!localStorage.getItem("sessionId")) {
      getSessionId().then((sessionId) => {
        localStorage.setItem("sessionId", sessionId);
      });
    }
  }, []);

  return <>{children}</>;
}
